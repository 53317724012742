<template>
  <div>
    <ProductListHorizontal
      :select-category-code="setCategory"
      :title="listTitle"
      :product-list="productsList"
      :list-link="props.listLink"
      :show-categories="showCategories"
      :navigation-class="navigationClass"
      is-image-path
      is-watched-product
      :list-type="props.type"
    />
  </div>
</template>

<script setup>
import { useProductsSliderStore } from '~/stores/productsSlider'

const { t } = useI18n()

const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  page: {
    type: Number,
    default: 1
  },
  limit: {
    type: Number,
    default: 10
  },
  title: {
    type: String,
    default: ''
  },
  listLink: {
    type: [Object, String],
    default: () => null
  },
  showCategories: {
    type: Boolean,
    default: false
  }
})

const listTitle = computed(() => {
  if (props.title) {
    return props.title
  }
  switch (props.type) {
    case 'ordered':
      return t('Скоро в продажі')
    case 'promotion':
      return t('Акційні товари')
    case 'new':
      return t('Новинки')
  }
})
const selectedCategory = ref(0)
const setCategory = code => {
  selectedCategory.value = code
}

const query = computed(() => {
  return selectedCategory.value !== 0
    ? {
        taxon: selectedCategory.value
      }
    : ''
})

const productsSliderStore = useProductsSliderStore()
const productsList = computed(
  () => productsSliderStore?.productsList[props.type] || []
)

useLazyAsyncData(
  `product-slider-${props.type}-category-${selectedCategory.value}`,
  () => {
    return productsSliderStore.fetchProductsByTag(query.value, props.type)
  },
  {
    watch: selectedCategory
  }
)

onMounted(() => {
  productsSliderStore.fetchProductsByTag(query.value, props.type)
})

const navigationClass = computed(() =>
  props.showCategories ? '!-translate-y-[110px]' : ''
)
</script>
